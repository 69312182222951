import * as THREE from 'three'
import UrlEncoder from '../services/utils/UrlEncoder'
import MathHelper from '../services/utils/MathHelper'
import SharpEvent from '../services/events/SharpEvent'
import ObjectHelper from '../services/utils/ObjectHelper'
import VectorHelper from '../services/utils/VectorHelper'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import { isEqual } from 'lodash'
import Viewer3DApi from '../Viewer3DApi'
import AnimationHelper from '../services/utils/AnimationHelper'

const Buffer = require('buffer/').Buffer

export default class CameraManager {
  config
  cameraChangeEvent
  api
  controls

  constructor(config) {
    this.config = config
    this.cameraChangeEvent = new SharpEvent()
    this.animating = false
  }

  componentDidMount(rendererDivRef) {
    this.rendererDivRef = rendererDivRef

    // Create a basic perspective camera
    this.camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      this.config.camera.near,
      this.config.camera.far
    )

    this.controls = new OrbitControls(this.camera, rendererDivRef)
    this.controls.enableDamping = this.config.camera.dampingFactor > 0
    this.controls.dampingFactor = this.config.camera.dampingFactor

    this.controls.enablePan = this.config.camera.enablePan
    this.controls.enableZoom =
      this.config.camera.enableZoom && !this.config.camera.scrollZoomToCursor
    this.controls.enableRotate = this.config.camera.enableRotate

    this.controls.autoRotateSpeed = this.config.camera.autoRotateSpeed
    this.controls.rotateSpeed = this.config.camera.rotateSpeed

    this.controls.zoomSpeed = this.config.camera.zoomSpeed

    this.controls.panSpeed = this.config.camera.panSpeed

    this.controls.minPolarAngle = MathHelper.toRadians(this.config.camera.minPolarAngle)
    this.controls.maxPolarAngle = MathHelper.toRadians(this.config.camera.maxPolarAngle)
    this.controls.minDistance = this.config.camera.minDistance
    this.controls.maxDistance = this.config.camera.maxDistance

    this.controls.addEventListener('change', this.cameraChanged.bind(this))

    this.setCameraPosition(this.config.camera.initialPosition)
    this.setCameraTarget(this.config.camera.initialTarget)

    this.updateMouseWheelEvent(
      false,
      this.config.camera.scrollZoomToCursor && this.config.camera.enableZoom
    )
  }

  componentWillReceiveProps(nextConfig) {
    this.controls.enableDamping = nextConfig.camera.dampingFactor > 0
    this.controls.dampingFactor = nextConfig.camera.dampingFactor

    this.controls.enablePan = nextConfig.camera.enablePan
    this.controls.enableZoom = nextConfig.camera.enableZoom && !nextConfig.camera.scrollZoomToCursor
    this.controls.enableRotate = nextConfig.camera.enableRotate

    this.controls.zoomSpeed = nextConfig.camera.zoomSpeed

    this.controls.panSpeed = nextConfig.camera.panSpeed

    // some of the configurations will be updated from the Viewer, when the updateFov is called
    this.controls.autoRotateSpeed = nextConfig.camera.autoRotateSpeed
    this.controls.rotateSpeed = nextConfig.camera.rotateSpeed
    this.controls.minPolarAngle = MathHelper.toRadians(nextConfig.camera.minPolarAngle)
    this.controls.maxPolarAngle = MathHelper.toRadians(nextConfig.camera.maxPolarAngle)

    this.controls.minDistance = nextConfig.camera.minDistance
    this.controls.maxDistance = nextConfig.camera.maxDistance

    if (
      nextConfig.camera.near !== this.config.camera.near ||
      nextConfig.camera.far !== this.config.camera.far
    ) {
      this.camera.near = nextConfig.camera.near
      this.camera.far = nextConfig.camera.far

      this.camera.updateProjectionMatrix()
    }

    if (!isEqual(nextConfig.camera.initialPosition, this.config.camera.initialPosition))
      this.setCameraPosition(nextConfig.camera.initialPosition)

    if (!isEqual(nextConfig.camera.initialTarget, this.config.camera.initialTarget))
      this.setCameraTarget(nextConfig.camera.initialTarget)

    this.updateMouseWheelEvent(
      this.config.camera.scrollZoomToCursor && this.config.camera.enableZoom,
      nextConfig.camera.scrollZoomToCursor && nextConfig.camera.enableZoom
    )

    this.config = nextConfig
  }

  componentWillUnmount() {
    this.controls.dispose()
    delete this.controls

    this.updateMouseWheelEvent(this.config.camera.scrollZoomToCursor, false)
  }

  update() {
    // required if controls.enableDamping = true, or if controls.autoRotate = true
    this.controls.update()
  }

  updateFov(width, height) {
    const vFOV =
      2 * Math.atan(Math.tan(MathHelper.toRadians(this.config.camera.fov) / 2) / (width / height))

    this.screenSize = { width: width, height: height }
    this.camera.fov = MathHelper.toDegrees(vFOV)
    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
  }

  exposeAPI(api) {
    this.api = api
    api.register(
      Viewer3DApi.TYPES.CAMERA,
      {
        getScreenSize: this.getScreenSize,
        setAutoRotate: this.setAutoRotate,
        getAutoRotate: this.getAutoRotate,
        getPosition: this.getCameraPosition,
        setPosition: this.setCameraPosition,
        getTarget: this.getCameraTarget,
        setTarget: this.setCameraTarget,
        setPositionAndTarget: this.setCameraPositionAndTarget,
        getState: this.getCameraState,
        setState: this.setCameraState,
        zoomIn: this.zoomIn,
        zoomOut: this.zoomOut,
        getScreenPosition: this.getScreenPosition,
        moveTo: this.moveTo
      },
      this
    )
  }

  /**
   * Provides the screen size.
   * @api viewer3d.camera
   * @return {Vector2} Size of the screen in pixels.
   */
  getScreenSize() {
    return this.screenSize
  }

  /**
   * Zooms the camera in.
   * @param {number} percentage Floating-point number between 0-1 indicating the distance percentage to zoom in (default is 0.1).
   * @param animation {AnimationOptions} If a value is set, the transition to the new location will be animated according to the defined options, otherwise the change will be performed instantly.
   * @api viewer3d.camera
   * @example
   * viewer3D.getCameraAPI().zoomIn(0.1, true)
   */
  zoomIn(percentage, animation) {
    percentage = ObjectHelper.coalesce(percentage, 0.1)
    animation = ObjectHelper.coalesce(animation, true)

    percentage = MathHelper.clamp(percentage, 0, 0.99)

    this.zoomInternal(1 - percentage, animation)
  }

  /**
   * Zooms the camera out.
   * @param percentage {number} Floating-point number between 0-1 indicating the distance percentage to zoom out (default is 0.1). Inverse calculation of zoom in, so that one operation can cancel the other.
   * @param animation {AnimationOptions} If a value is set, the transition to the new location will be animated according to the defined options, otherwise the change will be performed instantly.
   * @api viewer3d.camera
   * @example
   * viewer3D.getCameraAPI().zoomOut(0.1, true)
   */
  zoomOut(percentage, animation) {
    percentage = ObjectHelper.coalesce(percentage, 0.1)
    animation = ObjectHelper.coalesce(animation, true)

    percentage = MathHelper.clamp(percentage, 0, 0.99)

    this.zoomInternal(1 / (1 - percentage), animation)
  }

  zoomInternal(percentageDeduction, animation) {
    const position = this.getCameraPosition()
    const target = this.getCameraTarget()

    const sizedDirection = position.clone().sub(target)
    const size = sizedDirection.length()
    const direction = sizedDirection.normalize()
    const intendedDistance = size * percentageDeduction

    const newPosition = target.clone().add(direction.multiplyScalar(intendedDistance))

    this.setCameraPosition(newPosition, animation)
  }

  cameraChanged() {
    const currentPosition = this.getCameraPosition()
    const currentTarget = this.getCameraTarget()

    // unfortunately the library does not offer the means to differentiate between the several
    // types of camera change, so
    if (this.previousPosition && this.previousTarget) {
      // if the target is the same, we could be talking about a change in rotation or zoom
      if (currentTarget.equals(this.previousTarget)) {
        const currentLen = currentTarget.clone().sub(currentPosition).lengthSq()
        const previousLen = this.previousTarget.clone().sub(this.previousPosition).lengthSq()

        // if the distance is approximately the same, we had a rotation, which maintained distance
        // otherwise, it would be a zoom
        if (Math.abs(currentLen - previousLen) < 0.1) {
          this.config.camera.onCameraRotate(this.api)
        } else {
          this.config.camera.onCameraZoom(this.api)
        }
      } else {
        this.config.camera.onCameraPan(this.api)
      }
    }

    this.config.camera.onCameraChange(this.api)
    this.cameraChangeEvent.invoke()

    this.previousPosition = currentPosition.clone()
    this.previousTarget = currentTarget.clone()
  }

  updateMouseWheelEvent(previousState, currentState) {
    if (previousState === currentState) return

    if (previousState && !currentState) {
      this.rendererDivRef.removeEventListener('wheel', this.onMouseWheelFunction, false)
    } else {
      this.onMouseWheelFunction = this.onMouseWheel.bind(this)
      this.rendererDivRef.addEventListener('wheel', this.onMouseWheelFunction, false)
    }
  }

  onMouseWheel(e) {
    const baseWheelZoomSpeed = 0.5
    const multiplier = e.wheelDelta * baseWheelZoomSpeed * this.config.camera.zoomSpeed
    const rendererDivRect = this.rendererDivRef.getBoundingClientRect()

    // we have to convert the coordinates in canvas space [0,1] to screen space [-1,1], hence the calculation
    const screenX = (e.offsetX / rendererDivRect.width - 0.5) * 2
    const screenY = (1 - e.offsetY / rendererDivRect.height - 0.5) * 2

    const farClipPosition = new THREE.Vector3(screenX, screenY, 1).unproject(this.camera)

    const zoomDirection = farClipPosition.clone().sub(this.camera.position).normalize()

    const newPosition = this.camera.position
      .clone()
      .add(zoomDirection.clone().multiplyScalar(multiplier))
    const newTarget = this.controls.target
      .clone()
      .add(zoomDirection.clone().multiplyScalar(multiplier))

    this.setCameraPosition(newPosition, false)
    this.setCameraTarget(newTarget, false)

    this.config.camera.onCameraZoom(this.api)
    this.config.camera.onCameraChange(this.api)
    this.cameraChangeEvent.invoke()
  }

  /**
   * Starts/Stops the camera auto rotation.
   * @param state {boolean} True to start the camera, false to stop it.
   * @api viewer3d.camera
   */
  setAutoRotate(state) {
    this.controls.autoRotate = state
  }

  /**
   * Gets the state of camera auto rotation. True if it is running, false otherwise.
   * @api viewer3d.camera
   * @returns {boolean} True if the autorotation is enabled, false otherwise.
   */
  getAutoRotate() {
    return this.controls.autoRotate
  }

  /**
   * Gets the camera position.
   * @alias getPosition
   * @returns {Vector3} The 3D coordinates of the camera position.
   * @api viewer3d.camera
   */
  getCameraPosition() {
    return this.camera.position
  }

  /**
   * Sets the camera position.
   * @alias setPosition
   * @param position {Vector3} The 3D coordinates of the new position.
   * @param [animation] {AnimationOptions} If a value is set, the transition to the new target will be animated (see animation details in the AnimationOptions object page), otherwise the change will be performed instantly.
   * @api viewer3d.camera
   */
  setCameraPosition(position, animation) {
    const animationHelper = AnimationHelper.newOrSelf(
      animation,
      this.config.camera.animationDuration
    )

    animationHelper.animateVector(this.camera.position, position)
  }

  /**
   * Gets the camera target.
   * @alias getTarget
   * @returns {Vector3} The 3D coordinates of the camera target.
   * @api viewer3d.camera
   */
  getCameraTarget() {
    return this.controls.target
  }

  /**
   * Sets the camera target.
   * @alias setTarget
   * @param target {Vector3} The 3D coordinates of the new target.
   * @param [animation] {AnimationOptions} If a value is set, the transition to the new target will be animated (see animation details in the AnimationOptions object page), otherwise the change will be performed instantly.
   * @api viewer3d.camera
   * @example
   * viewer3D.getCameraAPI().setTarget({x : 100, y: 20, z: 39},{})
   */
  setCameraTarget(target, animation) {
    const animationHelper = AnimationHelper.newOrSelf(
      animation,
      this.config.camera.animationDuration
    )

    animationHelper.animateVector(this.controls.target, target)
  }

  /**
   * Sets the camera position and target at once, with the same animation options for both.
   * This call ensures that only 1 call to the onFinished callback function is performed.
   * @api viewer3d.camera
   * @alias setPositionAndTarget
   * @param position {Vector3} The 3D coordinates of the new position.
   * @param target {Vector3} The 3D coordinates of the new target.
   * @param animation {AnimationOptions} If a value is set, the transition to the new target will be animated (see animation details in the AnimationOptions object page), otherwise the change will be performed instantly.
   */
  setCameraPositionAndTarget(position, target, animation) {
    const animationHelper = new AnimationHelper(animation, this.config.camera.animationDuration)

    this.setCameraPosition(position, animationHelper)
    this.setCameraTarget(target, animationHelper)
  }

  /**
   * Gets a hash that represents the current camera position and orientation/target.
   * @returns {string} Base64 string (url-friendly) with the hash.
   * @api viewer3d.camera
   * @alias getState
   */
  getCameraState() {
    const position = this.getCameraPosition()
    const target = this.getCameraTarget()

    const buf = Buffer.alloc(6 * 4) // 4 bytes for each value
    const valueList = [position.x, position.y, position.z, target.x, target.y, target.z]

    for (let i = 0; i < valueList.length; i++) {
      buf.writeFloatBE(valueList[i], i * 4)
    }

    const base64String = buf.toString('base64')

    return UrlEncoder.base64EncodeUrl(base64String)
  }

  /**
   * Sets the current camera position and orientation/target from a hash.
   * @alias setState
   * @param state {string} A Base64 string (url-friendly) with the hash.
   * @param animation {AnimationOptions} If a value is set, the transition to the new location will be animated according to the defined options, otherwise the change will be performed instantly.
   * @api viewer3d.camera
   * @example
   * cameraApi.setState('RIUnV0PXyA_DAvSvQrEvDEKXTr9CpLmY', {duration : 3000})
   */
  setCameraState(state, animation) {
    const base64State = UrlEncoder.base64DecodeUrl(state)
    const buf = Buffer.from(base64State, 'base64')
    const valueList = []
    for (let i = 0; i < 6; i++) {
      valueList.push(buf.readFloatBE(i * 4))
    }

    const newPosition = new THREE.Vector3(valueList[0], valueList[1], valueList[2])
    const newTarget = new THREE.Vector3(valueList[3], valueList[4], valueList[5])

    this.setCameraPositionAndTarget(newPosition, newTarget, animation)
  }

  getOriginalDirection() {
    return VectorHelper.convertToVector3(this.config.camera.initialTarget)
      .sub(VectorHelper.convertToVector3(this.config.camera.initialPosition))
      .normalize()
  }

  getWorldDirection(direction) {
    const matrix = new THREE.Matrix4()
    matrix.extractRotation(this.camera.matrix)

    return direction.clone().applyMatrix4(matrix)
  }

  /**
   * Moves both the camera position and target simultaneously, maintaining the distance between the two.
   * The move is performed so that the camera direction is maintained.
   * The camera position will be placed at the indicated offset from the indicated point,
   * while the target will be placed at the same distance from the position as it was before.
   *
   * @param point {Vector3} The 3D coordinates of the new position.
   * @param offset {number} The distance from the point that the .
   * @param animation {AnimationOptions} If a value is set, the movement will be animated (see animation details in the AnimationOptions object page), otherwise the change will be performed instantly.
   * @api viewer3d.camera
   */
  moveTo(point, offset, animation) {
    const position = this.getCameraPosition()
    const target = this.getCameraTarget()
    const pointVector = VectorHelper.convertToVector3(point)

    const frontVector = target.clone().sub(position)
    const backDirection = position.clone().sub(target).normalize()
    const offsetPosition = pointVector.clone().add(backDirection.multiplyScalar(offset))
    const newTarget = offsetPosition.clone().add(frontVector)

    this.setCameraPositionAndTarget(offsetPosition, newTarget, animation)
  }

  /**
   * Calculates the 2D screen position of 3D point, from the current camera's perspective.
   * @param vector3D {Vector3} Location of the 3D point.
   * @return {Vector2} ThreeJs Vector2 indicating the 2D screen coordinates.
   * @api viewer3d.camera
   */
  getScreenPosition(vector3D) {
    const newVector = vector3D.clone()
    const camera = this.camera

    newVector.project(camera)

    const clientRect = this.rendererDivRef.getBoundingClientRect()

    const widthHalf = 0.5 * clientRect.width
    const heightHalf = 0.5 * clientRect.height

    newVector.x = newVector.x * widthHalf + widthHalf
    newVector.y = -(newVector.y * heightHalf) + heightHalf

    return new THREE.Vector2(newVector.x, newVector.y)
  }

  /**
   * Moves the camera so that it frames the given bounding box.
   */
  frameBox(boundingBox, direction, animation) {
    const camera = this.camera
    const frameOffset = this.config.camera.frameOffset

    if (!direction) direction = this.getCameraTarget().clone().sub(camera.position).normalize()

    const size = boundingBox.getCenter(new THREE.Vector3()).sub(boundingBox.min).length()
    const boundingDistance = size / Math.tan(MathHelper.toRadians(camera.fov / 2))

    const offsetDistance = boundingDistance + frameOffset

    // we should not allow the calculated distance to within the bounds
    let actualDistance = Math.max(offsetDistance, this.config.camera.minDistance)
    actualDistance = Math.min(actualDistance, this.config.camera.maxDistance)

    const newPosition = boundingBox
      .getCenter(new THREE.Vector3())
      .sub(direction.multiplyScalar(actualDistance))
    const newTarget = boundingBox.getCenter(new THREE.Vector3())

    this.setCameraPositionAndTarget(newPosition, newTarget, ObjectHelper.coalesce(animation, true))
  }
}
