import * as THREE from 'three'

export default class ChromaKeyMaterial extends THREE.ShaderMaterial {
  video
  keyColorObject
  videoTexture

  constructor(url, keyColor) {
    super()

    this.video = document.createElement('video')
    this.video.muted = true
    this.video.crossOrigin = '*'
    this.video.loop = true
    this.video.src = url
    this.video.load()

    this.keyColorObject = new THREE.Color(keyColor)
    this.videoTexture = new THREE.Texture(this.video)
    this.videoTexture.name = url

    this.videoTexture.minFilter = THREE.LinearFilter
    this.videoTexture.magFilter = THREE.LinearFilter

    this.setValues({
      uniforms: {
        theTexture: {
          type: 't',
          value: this.videoTexture
        },
        color: {
          type: 'c',
          value: this.keyColorObject
        }
      },
      vertexShader: `varying mediump vec2 vUv; void main(void) {
        vUv = uv;
        mediump vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        gl_Position = projectionMatrix * mvPosition;
      }`,
      fragmentShader: `
        uniform mediump sampler2D theTexture;
        uniform mediump vec3 color;
        varying mediump vec2 vUv;
        void main(void) {
          mediump vec3 tColor = texture2D( theTexture, vUv ).rgb;
          mediump float a = (length(tColor - color) - 0.5) * 7.0;
          gl_FragColor = vec4(tColor, a);
        }`,
      transparent: true
    })
  }

  startVideo = function () {
    this.video.play()
  }

  stopVideo = function () {
    this.video.pause()
    this.video.src = ''
  }

  update = function () {
    if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
      // videoImageContext.drawImage(video, 0, 0);
      if (this.videoTexture) {
        this.videoTexture.needsUpdate = true
        return true
      }
    }
  }
}

// export const ChromaKeyMaterial = function (url, keyColor) {
//   THREE.ShaderMaterial.call(this)

//   let video = document.createElement('video')
//   video.muted = true
//   video.crossOrigin = 'anonymous'
//   video.loop = true
//   video.src = url
//   video.load()

//   var keyColorObject = new THREE.Color(keyColor)

//   var videoTexture = new THREE.Texture(video)
//   videoTexture.minFilter = THREE.LinearFilter
//   videoTexture.magFilter = THREE.LinearFilter

//   this.startVideo = function () {
//     video.play()
//   }

//   this.stopVideo = function () {
//     video.pause()
//     video.src = ''
//   }

//   this.update = function () {
//     if (video.readyState === video.HAVE_ENOUGH_DATA) {
//       // videoImageContext.drawImage(video, 0, 0);
//       if (videoTexture) {
//         videoTexture.needsUpdate = true
//         return true
//       }
//     }
//   }

//   this.setValues({
//     uniforms: {
//       texture: {
//         type: 't',
//         value: videoTexture
//       },
//       color: {
//         type: 'c',
//         value: keyColorObject
//       }
//     },
//     vertexShader: `
//     varying mediump vec2 vUv;
//     void main(void)
//     {
//     	vUv = uv;
//     	mediump vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
//     	gl_Position = projectionMatrix * mvPosition;
//     }
//     `,
//     fragmentShader: `
//     uniform mediump sampler2D texture;
//     uniform mediump vec3 color;
//     varying mediump vec2 vUv;
//     void main(void)
//     {
//       mediump vec3 tColor = texture2D( texture, vUv ).rgb;
//       mediump float a = (length(tColor - color) - 0.5) * 7.0;
//       gl_FragColor = vec4(tColor, a);
//     }
//     `,
//     transparent: true
//   })
// }

// ChromaKeyMaterial.prototype = Object.create(THREE.ShaderMaterial.prototype)
