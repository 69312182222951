import SharpEvent from '../services/events/SharpEvent'
import Viewer3DApi from '../Viewer3DApi'
import { isEqual, merge } from 'lodash'
import ObjectHelper from '../services/utils/ObjectHelper'
import { NodeInfo } from '../model/definitions/NodeInfo'

export default class NodeManager {
  config
  cameraManager
  sceneManager
  nodePreparationEvent
  hasNodesInScene = false

  constructor(config, cameraManager, sceneManager) {
    this.config = config
    this.cameraManager = cameraManager
    this.sceneManager = sceneManager
    this.cameraManager.cameraChangeEvent.subscribe(this.onCameraChange, this)
    this.sceneManager.onLoadFinishEvent.subscribe(this.onLoadFinished, this)

    this.nodePreparationEvent = new SharpEvent()
  }

  componentDidMount(rendererDiv) {
    this.rendererDiv = rendererDiv
  }

  componentWillReceiveProps(nextConfig) {
    let shouldReloadDataSource = false

    if (!isEqual(nextConfig.dataSource, this.config.dataSource)) {
      shouldReloadDataSource = true
    }

    if (!isEqual(nextConfig.nodeTypes, this.config.nodeTypes)) {
      // if we are already reloading the datasource, dont' do the refresh
      // because the data will be new
      if (!shouldReloadDataSource) this.refreshNodeProperties(nextConfig)
    }

    this.config = nextConfig
  }

  refreshNodeProperties(config) {
    const nodeTypeConfig = config.nodeTypes

    for (const node of this.getNodes()) {
      node.applyDefaultOptions(nodeTypeConfig)
    }
  }

  update() {
    let shouldRender = false

    for (const child of this.sceneManager.getSceneObjects()) {
      if (child.material && child.material.update) {
        shouldRender |= child.material.update()
      }
    }

    return shouldRender
  }

  onLoadFinished(result) {
    this.nodePreparationEvent.invoke(result)

    // confirm if there is at least one node.
    // if there aren't, we can perform some optimizations, such as disable ray casting
    this.hasNodesInScene = result.objects.some((x) => x.node)
  }

  exposeAPI(api) {
    api.register(
      Viewer3DApi.TYPES.NODE,
      {
        isInScene: this.isInScene,
        getNodes: this.getNodes,
        getNode: this.getNode
      },
      this
    )
  }

  /**
   * Gets the nodes loaded in the currently loaded scene.
   * @param [nameFilter] {string|string[]} Name of the nodes (or list of names) that the operation should be applied to. Leave null/undefined to apply to all.
   * @return {Node[]} List of nodes in the scene.
   * @api viewer3d.node
   */
  getNodes(nameFilter) {
    return this.sceneManager
      .getSceneObjects()
      .filter((object3d) => Boolean(object3d.node))
      .map((object3d) => object3d.node)
      .filter((node) => ObjectHelper.appliesToObject(node, nameFilter))
  }

  /**
   * Gets the first node that matches the provided name. Same as calling getNodes, but for undefined/null names, it will not return anything.
   * @param name {string} Name of the node to be fetched.
   * @returns {Node} The node with the indicated name.
   * @api viewer3d.node
   */
  getNode(name) {
    return this.getNodes([name])[0]
  }

  /**
   * Checks if the given node is present in the scene, as part of an object.
   * @param node {Node} The node to be checked for.
   * @return {boolean} True if it present, false otherwise.
   * @api viewer3d.node
   */
  isInScene(node) {
    return Boolean(this.getNodes().includes(node))
  }

  onCameraChange() {
    this.getNodes().forEach((node) => node.onCameraChange(this.cameraManager.getCameraPosition()))
  }

  buildNode(threeJsObject, prefix, requestedType, nodeClass) {
    const currentScene = this.sceneManager.getCurrentScene()
    const nodeInfos = currentScene.nodes || {}

    // there must be a definition of the node, even if empty
    const nodeInfo = nodeInfos[threeJsObject.name]
    if (!nodeInfo) {
      // picking nodes defined
      if (threeJsObject.name.startsWith(prefix)) threeJsObject.visible = false

      return
    }

    const customNodeInfo = new NodeInfo(threeJsObject.name)
    customNodeInfo.alias = threeJsObject.name

    // if the mesh name follows the PICK/MARKER semantic format, we should infer
    // some defaults for the target scene and alias
    if (threeJsObject.name.startsWith(prefix)) {
      customNodeInfo.type = requestedType
      customNodeInfo.alias = customNodeInfo.linkedScene = this.decodeSemanticName(
        prefix,
        threeJsObject.name
      )
    }

    // the actual node type can still be overridden in the definition
    merge(customNodeInfo, nodeInfo)

    // this function is called for all objects, so we might
    // not know our actual node type until now
    if (customNodeInfo.type === requestedType) {
      threeJsObject.name = customNodeInfo.alias

      threeJsObject.node = new nodeClass(threeJsObject)
      threeJsObject.node.linkedScene = this.sceneManager.getScene(customNodeInfo.linkedScene)
      threeJsObject.node.individualOptions = customNodeInfo.options
      threeJsObject.node.meta = customNodeInfo.meta
      threeJsObject.node.cameraState = customNodeInfo.cameraState
      threeJsObject.node.enabled = customNodeInfo.enabled
      threeJsObject.node.targetModels = customNodeInfo.targetModels
      threeJsObject.visible = customNodeInfo.visible

      threeJsObject.node.applyDefaultOptions(this.config.nodeTypes)
    }
  }

  /**
   * Parses a surrogate id that is usually assigned in picking boxes (and now other nodes)
   */
  decodeSemanticName(prefix, sid) {
    // for retro-compatibility issues, we keep this.
    // TODO: Remove after version 2.0.0 or so
    if (sid.includes('__')) {
      const strSplit = sid.split('__')
      const result =
        strSplit.length > 3 ? strSplit[2].concat('_-').concat(strSplit[3]) : strSplit.pop()

      console.warn(
        'The semantic convention such as "' +
          sid +
          '" is no longer supported. Please replace with the format "' +
          prefix +
          '_' +
          result +
          '".'
      )

      return result
    } else {
      return sid.split('_')[1]
    }
  }
}
