import React, { createRef } from 'react'

import defaultProps from './Viewer3DDefaults'
import CameraManager from './rendering/CameraManager'
import SceneManager from './objects/SceneManager'
import FixedObjectManager from './objects/FixedObjectManager'
import RenderManager from './rendering/RenderManager'
import NodeManager from './objects/NodeManager'
import SkinManager from './objects/SkinManager'
import MarkerManager from './objects/MarkerManager'
import WireframeManager from './objects/WireframeManager'
import MeshManager from './objects/MeshManager'
import AnimationManager from './objects/AnimationManager'
import PlaneClippingManager from './rendering/PlaneClippingManager'
import NodeFrameManager from './objects/NodeFrameManager'
import NodeSelectionManager from './objects/NodeSelectionManager'
import NodeStatusManager from './objects/NodeStatusManager'
import IsolationManager from './objects/IsolationManager'
import PickingBoxManager from './objects/PickingBoxManager'
import ReportManager from './objects/ReportManager'
import ExtendedCameraManager from './objects/ExtendedCameraManager'
import Viewer3DApi from './Viewer3DApi'
import TWEEN from '@tweenjs/tween.js'
import CanvasResizeWatcher from './view/CanvasResizeWatcher'
import ProgressRenderer from './view/ProgressRenderer'

import { cloneDeep, isEqual, merge } from 'lodash'
import { SceneOptionsManager } from './objects/SceneOptionsManager'

/**
 * The Viewer3D Component is a react-based UI control for viewing, navigating and interacting with hierarchical
 * 3D models on the Web. These models can be located on a local or remote server, organized into a tree of nodes,
 * whereas each can contain several meshes.
 */
export default class Viewer3D extends React.Component {
  requestedAnimationFrame = undefined
  rendererDivRef = createRef(null)
  sceneManager
  renderManager
  cameraManager
  api
  state = {
    config: undefined,
    viewerManagers: []
  }

  constructor(props) {
    super(props)

    const config = merge({}, defaultProps, this.props)

    this.state = { config: config, viewerManagers: this.init(config) }
  }

  init(initialConfig) {
    this.cameraManager = new CameraManager(initialConfig)
    this.sceneManager = new SceneManager(initialConfig, this.cameraManager)
    this.renderManager = new RenderManager(initialConfig, this.sceneManager)

    const fixedObjectManager = new FixedObjectManager(this.sceneManager, this.cameraManager)
    const nodeManager = new NodeManager(initialConfig, this.cameraManager, this.sceneManager)
    const skinManager = new SkinManager(initialConfig, this.sceneManager, this.renderManager)
    const markerManager = new MarkerManager(
      initialConfig,
      this.sceneManager,
      this.renderManager,
      this.cameraManager,
      nodeManager
    )
    const wireframeManager = new WireframeManager(initialConfig, this.sceneManager)
    const meshManager = new MeshManager(initialConfig, this.sceneManager)
    const animationManager = new AnimationManager(initialConfig, this.sceneManager)
    const planeClippingManager = new PlaneClippingManager(
      initialConfig,
      this.renderManager,
      this.sceneManager,
      nodeManager
    )
    const nodeFrameManager = new NodeFrameManager(
      initialConfig,
      this.cameraManager,
      this.sceneManager,
      nodeManager
    )
    const nodeSelectionManager = new NodeSelectionManager(
      initialConfig,
      this.cameraManager,
      this.sceneManager,
      nodeManager,
      nodeFrameManager
    )
    const nodeStatusManager = new NodeStatusManager(
      initialConfig,
      this.cameraManager,
      this.sceneManager,
      nodeManager
    )
    const isolationManager = new IsolationManager(
      initialConfig,
      this.sceneManager,
      nodeSelectionManager,
      this.renderManager
    )
    const pickingBoxManager = new PickingBoxManager(initialConfig, this.sceneManager, nodeManager)
    const reportManager = new ReportManager(this.sceneManager)
    const extendedCameraManager = new ExtendedCameraManager(
      this.cameraManager,
      this.renderManager,
      this.sceneManager,
      nodeSelectionManager
    )

    const viewerManagers = [
      this.cameraManager,
      this.sceneManager,
      this.renderManager,
      fixedObjectManager,
      nodeManager,
      skinManager,
      markerManager,
      wireframeManager,
      meshManager,
      animationManager,
      planeClippingManager,
      nodeFrameManager,
      nodeSelectionManager,
      nodeStatusManager,
      isolationManager,
      pickingBoxManager,
      reportManager,
      extendedCameraManager
    ]

    viewerManagers.push(
      new SceneOptionsManager(initialConfig, this.sceneManager, [...viewerManagers])
    )

    this.api = new Viewer3DApi(this.loadConfiguration)
    viewerManagers.filter((x) => x.exposeAPI).forEach((x) => x.exposeAPI(this.api))

    return viewerManagers
  }

  componentDidMount() {
    // relay to all managers
    for (const viewerManager of this.state.viewerManagers) {
      if (viewerManager.componentDidMount)
        viewerManager.componentDidMount(this.rendererDivRef.current)
    }

    this.update()
  }

  static getDerivedStateFromProps(nextProps, state) {
    const nextConfig = merge({}, defaultProps, nextProps)

    return Viewer3D.updateConfigs(state, nextConfig)
  }

  static updateConfigs(state, newConfig) {
    if (isEqual(state.config, newConfig)) return null

    // relay to all managers
    for (const viewerManager of state.viewerManagers.filter((x) => x.componentWillReceiveProps)) {
      viewerManager.componentWillReceiveProps(newConfig)
    }

    return { config: newConfig }
  }

  /* shouldComponentUpdate(nextProps, nextState) {
    return nextState.configs !== nextProps.configs
  } */

  update() {
    if (!this.requestedAnimationFrame) {
      this.requestedAnimationFrame = window.requestAnimationFrame(this.animate)
    }
  }

  animate = () => {
    for (const viewerManager of this.state.viewerManagers.filter((x) => x.update)) {
      this.renderManager.updateShouldRenderer(viewerManager.update())
    }

    this.renderManager.updateShouldRenderer(TWEEN.update())
    this.renderManager.render(this.cameraManager.camera)

    this.requestedAnimationFrame = window.requestAnimationFrame(this.animate)
  }

  loadConfiguration = (name, options) => {
    const newConfig = cloneDeep(this.state.config)

    if (!Object.prototype.hasOwnProperty.call(newConfig, name)) {
      console.error('There is no configuration "' + name + '" available.')
      return
    }

    for (const propertyKey in options) {
      if (Object.prototype.hasOwnProperty.call(options, propertyKey)) {
        if (!Object.prototype.hasOwnProperty.call(newConfig[name], propertyKey)) {
          console.error(`There is no property "' ${propertyKey} '" under ' ${name} '" available.`)
          continue
        }

        newConfig[name][propertyKey] = options[propertyKey]
      }
    }

    Viewer3D.updateConfigs(this.state, newConfig)
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.requestedAnimationFrame)

    // relay to all managers
    for (const viewerManager of this.state.viewerManagers.filter((x) => x.componentWillUnmount)) {
      viewerManager.componentWillUnmount()
    }
  }

  onMouseEvent(e, mouseEventName) {
    // relay to all managers. eventName can be onMouseMove, onMouseDown, onMouseDoubleClick, onMouseUp
    for (const viewerManager of this.state.viewerManagers.filter((x) => x[mouseEventName])) {
      viewerManager[mouseEventName](e)
    }
  }

  render() {
    const secondStyle = { position: 'relative', height: 'auto', outline: 0 }

    if (this.state.config.renderer.width) secondStyle.width = this.state.config.renderer.width

    return (
      <div
        className='viewer3d'
        ref={this.rendererDivRef}
        style={Object.assign({}, this.state.config.style, secondStyle)}
        onPointerMove={(e) => this.onMouseEvent(e, 'onMouseMove')}
        onPointerDown={(e) => this.onMouseEvent(e, 'onMouseDown')}
        onDoubleClick={(e) => this.onMouseEvent(e, 'onMouseDoubleClick')}
        onPointerUp={(e) => this.onMouseEvent(e, 'onMouseUp')}
      >
        <CanvasResizeWatcher
          renderManager={this.renderManager}
          cameraManager={this.cameraManager}
          config={this.state.config}
        />

        <ProgressRenderer
          showProgress={this.state.config.renderer.showProgress}
          progressColor={this.state.config.renderer.progressColor}
          sceneManager={this.sceneManager}
          renderManager={this.renderManager}
        />
      </div>
    )
  }

  getAPI() {
    return this.api
  }
}
