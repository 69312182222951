/* eslint-disable */
export default {

  // #region datasource
  dataSource: {
    //type of datasource. Natively, this is the only one available
    type: 'SceneDataSource',

    //the url to the base folder
    url: null,

    //path to the json, relative to the base folder
    path: 'data.json',

    //path to the endpoint, relative to the base folder,
    //that provides the sizes of the files, in case the server is unable to
    //(for instance, if gzip/chunk-based compression is enabled)
    //it should return a json with key-value pairs where the keys are the file paths
    //(again, relative to the base folder) and the values are the sizes of the files,
    //in bytes.
    //if left null or empty, no attempt to fetch such a list will be done
    sizes: '',

    //the id of the first node to load. In the pbs example, it could be "356", "357"...
    initialNodeId: null,

    //indicates if every node loading should be cached
    //this will reduce loading times for a node visited a second time, at an expense of memory
    useCaching: false,

    //if true, all the node resources be loaded at start
    //This can be used together with the useCaching flag to achieve a smooth transition between scenes.
    preFetch: false,

    //callback event for when the datasource finishes loading
    onDataSourceLoad: (api) => {
    }
  },
  // #endregion datasource
  // #region scenes
  scenes: {

    //limit of scene references stored in the scene history,
    //used by the loadPreviousScene()
    historySize: 50,

    //Callback event for when the scenes are being loaded/finished
    onSceneLoading: (api, scene) => {
    },

    onSceneReady: (api, scene) => {
    },
  },
  // #endregion scenes
  // #region nodes
  nodes: {

    //indicates if double-clicking a pickingbox/
    //marker will load the linked scene, i.e. load the
    //scene that the node refers to
    doubleClickToLoad: true,

    //indicates if more than one node can be
    //selected at once using the Shift key
    allowMultiSelect: true,

    //color and opacity applied to the meshes for the
    //isolation feature
    isolationColor: 'rgba(255,255,255,0.2)',

    //indicates if default association of nodes to
    //meshes based on their naming should occur.
    //Otherwise, it will only use the "targetModels"
    //field in the data source
    useDefaultIsolation: true,

    //indicates if the selection of a node with the
    //mouse/touch will trigger the isolation effect.
    //if false, it can only be triggered via API functions.
    selectToIsolate: true,


    //indicates if a mouse click/touch on an empty area
    //(having no selectable items) should trigger deselection
    //of nodes
    clickOnVoidToDeselect : true,

    //Callback event for when the nodes are selected, deselected,
    //hovered, unhovered, double-clicked or if being loaded/finished
    //loading (onready).
    //The affected node is passed, along with the origin, which can be
    //'ui' or 'user', depending if the event happened as part of an
    //api call or from a user interaction (through mouse/touch).
    onNodeSelect: (api, targetNode, origin, selectedNodeList) => {},
    onNodeDeselect: (api, targetNode, origin, selectedNodeList) => {},
    onNodeHover: (api, targetNode) => {},
    onNodeUnhover: (api, targetNode) => {},
    onNodeDoubleClick: (api, targetNode) => {}
  },
  // #endregion nodes
  // #region nodeTypes
  nodeTypes: {
    //properties of the PickingBox object
    PickingBox: {

      //fill colors when the PickingBox is in the disabled, hovered, selected or normal state.
      //There is a priority of color application: selected => hovered => disabled => normal.
      //This means that, for instance, if an object is hovered and selected at the same time,
      //the selected color will prevail. A color can be set to null, though, meaning
      //that it won’t take part in the chain.
      //The color is defined as a string, as in a css definition, stating the rgba color components.
      disabledFillColor: 'rgba(0,0,0,0.3)',
      normalFillColor: 'rgba(0,0,0,0)',
      hoveredFillColor: 'rgba(126,0,128,0.5)',
      selectedFillColor: 'rgba(255,200,0,0.5)',

      //border colors follow the same logic as explained for the fill colors
      disabledBorderColor: null,
      normalBorderColor: null,
      hoveredBorderColor: 'rgba(0,0,255,1)',
      selectedBorderColor: 'rgba(0,255,0,1)',

      //indicates if the original diffuse texture of the picking boxes should disabled
      //when the pickingbox is hovered or selected
      disableTextures: true,

      //To improve visibility of the contents behind the PickingBoxes, these support progressive
      //transparency, meaning that the fill color will become less opaque when the camera is closer
      //to the object.
      //This controls the opacity at close range. Setting it to 1 will disable the progressive transparency.
      nearAlpha: 0.05,

      //'nearDistance' indicate at what distance the ‘nearAlpha’ value will be used
      // and 'farDistance' indicates at what distance the default color alpha
      //(as indicated in the disabled-, normal-, hovered- and selectedFillColor fields) will be used.
      nearDistance: 500,
      farDistance: 2000,

      //duration (in milliseconds) of the color transitions/animations
      duration: 200
    },
    Marker: {

      //fill colors when the Marker is in the disabled, hovered, selected or normal state.
      //see the PickingBox's fillColor options for more information on the priority chain
      //The color is defined as a string, as in a css definition, stating the rgba color components.
      disabledColor: 'rgba(255,255,255,0.3)',
      normalColor: 'rgba(255,255,255,1)',
      hoveredColor: 'rgba(0,0,255,0.3)',
      selectedColor: 'rgba(255,0,0,0.3)',

      //duration (in milliseconds) of the color transitions/animations
      duration: 200
    }
  },
  // #endregion nodeTypes
  // #region style
  //style attributes of the div that encloses the webgl canvas
  //it supports many other attributes, namely all kinds of css attributes
  //but their naming should be adjusted to the react standards
  style: {
    backgroundImage: '',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    width: '100%',
    height: 'auto'
  },
  // #endregion style
  // #region renderer
  renderer: {

    //width of the control, in pixels
    //default is null, so that it adjusts to the containing div
    width: null,

    //height of the control, in pixels
    //default is null, so that it uses the ratio for calculation
    height: null,

    //when the above are not defined, it is used to set a constant
    //view ratio, so width divided by height.
    ratio: 2.2,

    //indicates if double-side rendering should be enabled for all loaded models
    enableDoubleSide: false,

    //indicates if wireframe rendering should be enabled for all loaded models
    enableWireframe: false,

    //color of the line of the wireframe.
    wireframeColor : 'rgba(255,255,255,1)',

    //indicates if anti-aliasing should be enabled
    antialias: true,

    //if enabled, a diagnostics window will appear on the top-right
    //corner of the viewer, indicating the framerate
    showStats: true,

    //if enabled, the default progressbar will be shown during loading
    //activities
    showProgress: true,

    //color of the default progressbar text and border
    progressColor: 'rgba(255,255,255,1)',

    //if enabled, 3 colored arrows will be drawn at the position (0,0,0),
    //Red for X axis, green for the Y axis, blue for the Z axis.
    axesHelper: false,

    //supersampling samples, more reduces anti-aliasing, but at higher
    //rendering cost. 0 disables supersampling.
    ssaaSamples: 4,

    //the intensity of the outline effect on the picking boxes
    borderStrength: 3.0,

    //amount of glow of the outline effect on the picking boxes
    borderGlow: 0.0,

    //the thickness of the outline borders on the picking boxes
    borderThickness: 1.0,

    //the period of the "pulsating" effect of the outline on the picking boxes.
    //0 means no pulsation.
    borderPulsePeriod: 0,

    //indicates if the clipping plane (used for cutting effects) is enabled
    clipPlaneEnabled: false,

    //distance from the origin of the clipping plane
    clipPlaneOffset: 0,

    //normal vector of the clipping plane, i.e. the direction that the plane is facing
    //expects an object with x,y,z components
    clipPlaneNormal: {x: 1, y: 0, z: 0},

    //the color of the clipping cap. If set to null
    //no cap will be applied.
    clipSectionColor: 'rgb(255,255,255)'
  },
  // #endregion renderer
  // #region light
  light: {

    //intensity of the ambient light
    intensity: 2.0,

    //color tint of the ambient light
    color: 'rgb(153,153,153)'
  },
  // #endregion light

  // #region camera
  camera: {

    //near and far frustum clipping distances of the perspective camera
    near: 0.1,
    far: 10000,

    //horizontal field of view, in degrees
    fov: 120,

    //if true, rotation using the left mouse button (or 1 finger-drag) will be enabled.
    enableRotate: true,

    //if true, panning with the right mouse (or 3-finger drag) will be enabled.
    enablePan: true,

    //if true, zooming using the mouse wheel (or 2 finger pinch) will be enabled.
    enableZoom: true,

    //the initial camera position and target, in absolute coordinates
    //expects an object with coordinates x, y and z with numeric values
    initialPosition: {x: 100, y: 100, z: 100},
    initialTarget: {x: 0, y: 0, z: 0},

    //vertical camera rotation limits, defined in polar angles, in degrees
    //For instance, at minPolarAngle = 0 the camera will, at most, stand at a top-down view
    //and maxPolarAngle=90, the camera will not rotate below the XY-plane
    minPolarAngle: 0,
    maxPolarAngle: 180,

    //default duration, in milliseconds, used when camera animations takes place
    animationDuration: 750,

    //the speed of the camera rotation. Larger values mean the
    //camera will rotate further for the same mouse/drag movement
    rotateSpeed: 0.2,

    //the speed of auto-rotation, when enabled
    autoRotateSpeed: 2.0,

    //if true, automatic camera framing will occur when a node
    //is selected on the scene
    selectionFraming: true,

    //if true, automatic camera framing will occur where a scene
    //is loaded
    loadFraming: true,

    //absolute offset, in addition to the calculated frame distance, at which the camera
    //will stand from the object after framing is performed. Positive values
    //indicate that the camera will be placed further away from the object
    //that the framing distance, while negative values mean it will be placed closer.
    frameOffset: 0,

    //establishes the minimum and maximum distance that the camera can navigate
    //towards or away from the target
    minDistance: 0,
    maxDistance: Infinity,

    //zoom factor - larger values mean the camera will zoom further for the same
    //pinch/scroll wheel delta
    zoomSpeed: 1,

    //pan speed - larger values mean the camera will pan further for the same mouse/finger drag
    panSpeed : 1,

    //if true, the camera will zoom in the direction of the current mouse position
    //(like Google Maps), otherwise it will zoom towards the current screen center.
    scrollZoomToCursor: true,

    //if true, a colored sphere will be drawn at the location of the camera target
    //which can be useful for debugging
    targetHelper : false,

    //Provides an effect of inertia when spinning. It can be used to can be used to give a sense
    //of weight to the controls. The lower the damping factor, the higher is the rotation sensitivity
    //A value of 0 disables damping. Values around 0.05 provide more noticeable effects.
    dampingFactor : 0,

    //Callback events for when the camera position/target changes (in general) or,
    //more specifically, when the camera pans, zooms or rotates
    onCameraChange: (api) => {},
    onCameraPan: (api) => {},
    onCameraZoom: (api) => {},
    onCameraRotate: (api) => {}
  },
  // #endregion camera

  // #region animation
  animation: {

    //possible values are 'paused', 'loopOnce', 'loopOnceInverted','loopPingPong'
    initialState: 'loopPingPong'
  },
  // #endregion animation
};
