import PathHelper from '../../services/utils/PathHelper'
import JSONLoader from '../../services/loaders/JSONLoader'
import * as THREE from 'three'

import ChromaKeyMaterial from '../../services/webgl/ThreexChromakey'

export default class VideoContentLoader {
  async loadContent(contentItem, output, dataSource, onLoadProgress, onLoadFinished) {
    const videosFileUrl = PathHelper.getFullUrl(dataSource.url, contentItem.url)

    const data = await JSONLoader.loadJsonObject(videosFileUrl)
    for (const videoEntry in data) {
      const value = data[videoEntry]

      for (const obj of output.objects) {
        if (obj instanceof THREE.Mesh && obj.name === videoEntry) {
          const videoUrl = PathHelper.getFullUrl(dataSource.url, value.path)

          obj.material = new ChromaKeyMaterial(videoUrl, value.chromaKey)
          obj.material.startVideo()
        }
      }
    }

    onLoadFinished(undefined, output)
  }
}
