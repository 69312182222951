export default {
  coalesce: (...data) => {
    for (const index of data) {
      if (index !== null && index !== undefined) return index
    }
  },

  appliesToObject(object, objectFilter) {
    // if the filter is empty, it applies
    if (!objectFilter) return true

    // if the filter is a list, ensure that the list contains the object name
    if (Array.isArray(objectFilter) && objectFilter.includes(object.name)) return true

    // otherwise, only accept it if the filter actually matches the object name
    return object.name === objectFilter
  }
}
