import { ApiObject } from './utils/ApiObject'

export default class Viewer3DApi extends ApiObject {
  onLoadConfiguration
  static TYPES = {
    NODE: 'Node',
    CAMERA: 'Camera',
    MARKER: 'Marker',
    SKIN: 'Skin',
    ANIMATION: 'Animation',
    SCENE: 'Scene'
  }

  ENUM_EVENTS = {
    DATASOURCE: {
      LOAD: 'onDataSourceLoad'
    },
    NODE: {
      READY: 'onNodeReady',
      SELECT: 'onNodeSelect',
      DESELECT: 'onNodeDeselect',
      LOADING: 'onNodeLoading',
      HOVER: 'onNodeHover',
      UNHOVER: 'onNodeUnhover',
      DBLCLICK: 'onNodeDoubleClick'
    },
    CAMERA: {
      CHANGED: 'onCameraChange',
      PAN: 'onCameraPan',
      ZOOM: 'onCameraZoom',
      ROTATE: 'onCameraRotate'
    }
  }

  listEvents = () => this.ENUM_EVENTS

  constructor(onLoadConfiguration) {
    super()
    this.onLoadConfiguration = onLoadConfiguration
  }

  loadConfiguration = () => this.onLoadConfiguration

  getSceneAPI = () => this.get(Viewer3DApi.TYPES.SCENE)

  getCameraAPI = () => this.get(Viewer3DApi.TYPES.CAMERA)

  getNodeAPI = () => this.get(Viewer3DApi.TYPES.NODE)

  getMarkerAPI = () => this.get(Viewer3DApi.TYPES.MARKER)

  getSkinAPI = () => this.get(Viewer3DApi.TYPES.SKIN)

  getAnimationAPI = () => this.get(Viewer3DApi.TYPES.ANIMATION)
}
