import * as THREE from 'three'

import { isEqual } from 'lodash'
import SharpEvent from '../services/events/SharpEvent'
import ObjectHelper from '../services/utils/ObjectHelper'
import Viewer3DApi from '../Viewer3DApi'
import SceneDataSource from '../model/SceneDataSource'

export default class {
  datasourceTypes = { SceneDataSource: SceneDataSource }
  config
  cameraManager
  scene
  group

  sceneHistory
  loadedScene
  sceneLoaded

  onLoadProgressEvent
  onLoadFinishEvent
  onSceneChanged
  sceneContentChangedEvent

  isCancelled
  allBoundingBox

  constructor(config, cameraManager) {
    this.config = config
    this.cameraManager = cameraManager
    this.scene = new THREE.Scene()
    this.group = new THREE.Group()

    this.sceneHistory = []

    this.scene.add(this.group)

    this.sceneLoaded = false

    this.onLoadProgressEvent = new SharpEvent()
    this.onLoadFinishEvent = new SharpEvent()

    this.onSceneChanged = new SharpEvent()
    this.sceneContentChangedEvent = new SharpEvent()

    this.setupDataSource(config)
  }

  componentDidMount(rendererDiv) {
    this.rendererDiv = rendererDiv

    this.loadDataSource()
  }

  componentWillReceiveProps(nextConfig) {
    let shouldReloadDataSource = false

    if (!isEqual(nextConfig.dataSource, this.config.dataSource)) {
      this.setupDataSource(nextConfig)
      shouldReloadDataSource = true
    }

    this.config = nextConfig

    if (shouldReloadDataSource) {
      this.sceneHistory = []
      this.loadedScene = undefined

      this.clearTransientObjects()
      this.loadDataSource()
    }
  }

  loadDataSource() {
    // have the data source load the data
    this.dataSource.load((err) => {
      if (err) console.error('Error on loading.', err)
      else {
        this.config.dataSource.onDataSourceLoad(this.api)

        const initialScene = this.dataSource.getFirstScene()

        if (!initialScene) {
          console.error('The given data does not have the indicated initial node.')
        } else {
          this.loadScene(initialScene)
        }
      }
    })
  }

  clearTransientObjects() {
    let removeIndex = 0
    const numChildren = this.group.children.length

    for (let i = 0; i < numChildren; i++) {
      const child = this.group.children[removeIndex]
      if (!child.persistent) {
        this.group.remove(child)
      } else removeIndex++
    }
  }

  setupDataSource(config) {
    // make sure we don't get callbacks from the old datasource
    if (this.dataSource) {
      this.dataSource.disposed = true
    }

    this.dataSource = new this.datasourceTypes[config.dataSource.type]()

    Object.keys(config.dataSource).forEach((property) => {
      this.dataSource[property] = config.dataSource[property]
    })
  }

  storeInHistory(scene) {
    if (scene && this.config.scenes.historySize > 0) {
      // restrict the number of scenes in the history
      while (this.sceneHistory.length + 1 > this.config.scenes.historySize) {
        this.sceneHistory.shift()
      }

      this.sceneHistory.push(scene)
    }
  }

  onLoadProgress(progress) {
    if (!this.isCancelled) {
      this.onLoadProgressEvent.invoke(progress)
    }
  }

  onLoadFinished(err, result) {
    if (err) console.error('There was an error loading the content.')
    else {
      const objects = result.objects

      objects.forEach((object) => {
        this.group.add(object)
      }, this)

      // warn all the interested components that subscribe to this event
      this.onLoadFinishEvent.invoke(result)

      this.allBoundingBox = this.calculateBoundingBox(objects)

      // if we have requested to frameBox a child node once the parent node was loaded
      // do so now
      if (this.config.camera.loadFraming) {
        const animation = {
          onFinish: () => this.config.scenes.onSceneReady(this.api, this.loadedScene)
        }
        this.cameraManager.frameBox(this.allBoundingBox, null, animation)
      } else {
        // warn anybody that may be interested
        this.config.scenes.onSceneReady(this.api, this.loadedScene)

        this.sceneContentChangedEvent.invoke()
      }

      this.sceneLoaded = true
    }
  }

  exposeAPI(api) {
    this.api = api

    api.register(
      Viewer3DApi.TYPES.SCENE,
      {
        addSceneObject: this.addSceneObject,
        removeSceneObject: this.removeSceneObject,
        getScenes: this.getScenes,
        getScene: this.getScene,
        getCurrentScene: this.getCurrentScene,
        getSceneObjects: this.getSceneObjects,
        loadScene: this.loadScene,
        loadPreviousScene: this.loadPreviousScene,
        frameScene: this.frameScene,
        setObjectVisibility: this.setObjectVisibility
      },
      this
    )
  }

  /**
   * Loads a node to the renderer.
   * @param {SceneInfo} scene The node to be loaded. Does nothing if the node is currently loaded.
   * @api viewer3d.scene
   */
  loadScene(scene) {
    // make sure that the node is different from this one
    if (this.loadedScene !== scene) {
      // store the previous scene (if set) to the history
      this.storeInHistory(this.loadedScene)

      this.config.scenes.onSceneLoading(this.api, scene)

      this.sceneLoaded = false

      this.clearTransientObjects()

      this.loadedScene = scene

      this.onSceneChanged.invoke(scene)

      this.dataSource.getContent(
        scene,
        this.onLoadProgress.bind(this),
        this.onLoadFinished.bind(this)
      )
    }
  }

  /**
   * Frames the whole scene in the screen, using the current camera direction or the provided one. Works only if a scene is loaded.
   * @param [animation] {AnimationOptions} If a value is set, the transition to the new location will be animated according to the defined options, otherwise the change will be performed instantly.
   * @param [direction] {Vector3} A vector3 indicating the camera direction to use in the frame. If null, the current camera direction will be used.
   * @api viewer3d.scene
   */
  frameScene(animation, direction) {
    this.cameraManager.frameBox(this.allBoundingBox, direction, animation)
  }

  /**
   * Gets the list of available scenes.
   * @param [nameFilter] {string|string[]} Name of the scene (or list of names) that the operation should be applied to. Leave null/undefined to apply to all.
   * @api viewer3d.scene
   * @returns {SceneInfo[]} The list of available scenes (matching the indicated filter, if provided).
   */
  getScenes(nameFilter) {
    return this.dataSource
      .getScenes()
      .filter((scene) => ObjectHelper.appliesToObject(scene, nameFilter))
  }

  /**
   * Gets the first scene that matches the provided name.  Same as calling getScenes, but for undefined/null names, it will not return anything.
   * @param name {string} Name of the scene to be fetched.
   * @returns {SceneInfo} The scene with the indicated name.
   * @api viewer3d.scene
   */
  getScene(name) {
    return this.getScenes([name])[0]
  }

  /**
   * Gets the scene that is currently loaded.
   * @returns {SceneInfo} The currently loaded scene.
   * @api viewer3d.scene
   */
  getCurrentScene() {
    return this.loadedScene
  }

  calculateBoundingBox(objects) {
    const boundingBox = new THREE.Box3()
    objects.forEach((object) => {
      if (object.node) {
        // sprites may have to get their BoundingBox through this means
        boundingBox.union(object.node.boundingBox)
      } else if (object.geometry !== undefined)
        boundingBox.union(new THREE.Box3().setFromObject(object))
    })

    return boundingBox
  }

  /**
   * Goes back (i.e. reloads) the scene that was previously loaded, if any.
   * @api viewer3d.scene
   */
  loadPreviousScene() {
    const previousScene = this.sceneHistory.pop()
    if (previousScene) {
      this.loadScene(previousScene)
    }
  }

  /**
   * Gets a list of all current scene objects, including nodes, meshes and lights.
   * @param [nameFilter] {string|string[]} Name of the object (or list of names) that the operation should be applied to. Leave null/undefined to apply to all.
   * @returns {object[]} A list of all the objects in the currently loaded scene.
   * @api viewer3d.scene
   */
  getSceneObjects(nameFilter) {
    return this.group.children.filter((object) => ObjectHelper.appliesToObject(object, nameFilter))
  }

  /**
   * Sets the visibility of the objects that match the passed filter.
   * @param visibility {boolean} New visibility status that is to be assigned to the objects.
   * @param [nameFilter] {string|string[]} Name of the object (or list of names) that the operation should be applied to. Leave null/undefined to apply to all.
   * @api viewer3d.scene
   */
  setObjectVisibility(visibility, nameFilter) {
    let contentChanged = false
    for (const sceneObject of this.getSceneObjects(nameFilter)) {
      contentChanged |= sceneObject.visible !== visibility
      sceneObject.visible = visibility
    }

    if (contentChanged) this.sceneContentChangedEvent.invoke()
  }

  /**
   * Adds a given scene object to the scene.
   * @param sceneObject {object} Object to be added.
   * @api viewer3d.scene
   */
  addSceneObject(sceneObject) {
    this.group.add(sceneObject)
    this.sceneContentChangedEvent.invoke()
  }

  /**
   * Removes a given scene object from the scene.
   * @param sceneObject {object} Object to be removed.
   * @api viewer3d.scene
   */
  removeSceneObject(sceneObject) {
    this.group.remove(sceneObject)
    this.sceneContentChangedEvent.invoke()
  }

  /**
   * Gets the meshes and all its subtree submeshes, starting from the root (or from the optionally provided mesh)
   * @param [threeJsObject] {object} (Optional)
   * @returns {Mesh[]} List of three.js meshes
   */
  getMeshChildren(threeJsObject) {
    if (!threeJsObject) threeJsObject = this.group

    const meshes = []
    const groups = [threeJsObject]

    while (groups.length > 0) {
      const item = groups.pop()
      for (const child of item.children) {
        if (child.isMesh && child.geometry !== undefined) {
          meshes.push(child)
        }

        if (child.children && child.children.length > 0) {
          groups.push(child)
        }
      }
    }

    return meshes
  }
}
