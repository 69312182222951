import PathHelper from '../../services/utils/PathHelper'
import JSONLoader from '../../services/loaders/JSONLoader'
import * as THREE from 'three'
import VectorHelper from '../../services/utils/VectorHelper'
import ContentItemTransformer from './common/ContentItemTransformer'

export default class LightContentLoader {
  constructor() {
    this.lightTypes = {
      Point: () => new THREE.PointLight(),
      Directional: () => new THREE.DirectionalLight(),
      Spot: () => new THREE.SpotLight(),
      Ambient: () => new THREE.AmbientLight()
    }

    this.debugHelperTypes = {
      Point: (light) => new THREE.PointLightHelper(light, 10),
      Directional: (light) => new THREE.DirectionalLightHelper(light, 10),
      Spot: (light) => new THREE.SpotLightHelper(light)
    }
  }

  async loadContent(contentItem, output, dataSource, onLoadProgress, onLoadFinished) {
    const lightsFileUrl = PathHelper.getFullUrl(dataSource.url, contentItem.url)
    const contentItemTransformer = new ContentItemTransformer(contentItem)
    output.objects = output.objects || []

    try {
      const data = await JSONLoader.loadJsonObject(lightsFileUrl)

      for (const lightName in data) {
        const lightData = data[lightName]
        const type = lightData.type || 'Point'

        const light = this.lightTypes[type]()
        light.name = lightName
        light.color = new THREE.Color(lightData.color || 0xffffff)
        light.intensity = lightData.intensity ?? 1
        light.position.copy(
          VectorHelper.convertToVector3(lightData.position || { x: 0, y: 0, z: 0 })
        )

        if (lightData.target) {
          const targetObject = new THREE.Object3D()
          targetObject.position.copy(
            VectorHelper.convertToVector3(lightData.target || { x: 0, y: 0, z: 0 })
          )
          output.objects.push(targetObject)

          light.target = targetObject
          light.target.updateMatrixWorld()
        }

        if (lightData.debug) {
          const debugHelperType = this.debugHelperTypes[type];
          if(debugHelperType){
            const debugHelper = debugHelperType(light)
            output.objects.push(debugHelper)
          }
        }

        contentItemTransformer.apply(light)

        // don't even add the object to the scene if the intensity is 0
        if (light.intensity > 0)
          output.objects.push(light)
      }

      onLoadFinished(undefined, output)
    } catch (error) {
      console.error(error)
    }
  }
}
