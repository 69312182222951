import * as THREE from 'three'
import Color from '../utils/Color'

export default class WireframeManager {
  config
  sceneManager

  constructor(config, sceneManager) {
    this.config = config
    this.sceneManager = sceneManager

    this.sceneManager.onLoadFinishEvent.subscribe(this.onLoadFinished, this)
  }

  componentWillReceiveProps(nextConfig) {
    if (this.config.renderer.enableWireframe !== nextConfig.renderer.enableWireframe) {
      this.updateWireframe(nextConfig.renderer.enableWireframe, nextConfig.renderer.wireframeColor)
    }

    if (
      nextConfig.renderer.enableWireframe &&
      this.config.renderer.wireframeColor !== nextConfig.renderer.wireframeColor
    ) {
      for (const mesh of this.sceneManager.getMeshChildren()) {
        mesh.wireframeChild.material.color = new THREE.Color(
          new Color(nextConfig.renderer.wireframeColor).getThreeColor()
        )
      }
    }

    this.config = nextConfig
  }

  componentWillUnmount() {
    this.sceneManager.onLoadFinishEvent.unsubscribeAll(this)
  }

  onLoadFinished() {
    this.updateWireframe(this.config.renderer.enableWireframe, this.config.renderer.wireframeColor)
  }

  updateWireframe(enableWireframe, color) {
    if (enableWireframe) {
      for (const mesh of this.sceneManager.getMeshChildren()) {
        // add the wireframe lines
        const geo = new THREE.EdgesGeometry(mesh.geometry) // or WireframeGeometry
        const mat = new THREE.LineBasicMaterial({
          color: new Color(color).getThreeColor()
        })
        const wireframe = new THREE.LineSegments(geo, mat)
        mesh.add(wireframe)
        mesh.wireframeChild = wireframe

        // set the material colors
        // child.material =
      }
    } else {
      for (const mesh of this.sceneManager.getMeshChildren()) {
        if (mesh.wireframeChild) {
          mesh.remove(mesh.wireframeChild)
          mesh.wireframeChild = undefined
        }
      }
    }
  }
}
